._1asagl30 {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
._1asagl31 {
  flex-grow: 1;
}
.MuiButtonBase-root {
  box-shadow: none;
  border-radius: 999px;
  background-color: var(--bilgz53);
  text-transform: none;
  font-family: var(--_15uvmkr1);
  transition: var(--png5tp11);
  padding-left: 1.6em;
  padding-right: 1.6em;
}
.MuiButtonBase-root:hover {
  box-shadow: none;
  background-color: var(--bilgz54);
  scale: 1.05;
}
.MuiTypography-root {
  font-family: var(--_15uvmkr1);
}
.MuiInputBase-root {
  font-family: var(--_15uvmkr1);
}
.MuiInputLabel-root {
  position: relative;
  transform: none;
  margin-bottom: var(--g5uqz0a);
  font-size: var(--_15uvmkro);
  font-family: var(--_15uvmkr0);
  color: var(--bilgz53);
}
.MuiInputLabel-asterisk {
  color: #e20000;
}
.MuiOutlinedInput-notchedOutline {
  top: 0;
}
.MuiOutlinedInput-notchedOutline > legend {
  display: none;
}
.MuiInputLabel-root.Mui-focused {
  color: var(--bilgz53);
}
.MuiFormHelperText-root {
  padding-top: var(--g5uqz08);
  margin: 0;
  font-family: var(--_15uvmkr1);
}
/* Document
 * ========================================================================== */

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Backgrounds do not repeat by default (opinionated).
 */

*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  background-repeat: no-repeat; /* 2 */
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */

::before,
::after {
  text-decoration: inherit; /* 1 */
  vertical-align: inherit; /* 2 */
}

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Change the line height in all browsers (opinionated).
 * 3. Breaks words to prevent overflow in all browsers (opinionated).
 * 4. Use a 4-space tab width in all browsers (opinionated).
 * 5. Remove the grey highlight on links in iOS (opinionated).
 * 6. Prevent adjustments of font size after orientation changes in iOS.
 */

:where(:root) {
  cursor: default; /* 1 */
  line-height: 1.5; /* 2 */
  overflow-wrap: break-word; /* 3 */
  -moz-tab-size: 4; /* 4 */
  tab-size: 4; /* 4 */
  -webkit-tap-highlight-color: transparent; /* 5 */
  -webkit-text-size-adjust: 100%; /* 6 */
}

/* Sections
 * ========================================================================== */

/**
 * Remove the margin in all browsers (opinionated).
 */

:where(body) {
  margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */

:where(h1) {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * Remove the margin on nested lists in Chrome, Edge, and Safari.
 */

:where(dl, ol, ul) :where(dl, ol, ul) {
  margin: 0;
}

/**
 * 1. Correct the inheritance of border color in Firefox.
 * 2. Add the correct box sizing in Firefox.
 */

:where(hr) {
  color: inherit; /* 1 */
  height: 0; /* 2 */
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */

:where(nav) :where(ol, ul) {
  list-style-type: none;
  padding: 0;
}

/**
 * Prevent VoiceOver from ignoring list semantics in Safari (opinionated).
 */

:where(nav li)::before {
  content: "\200B";
  float: left;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 * 3. Prevent overflow of the container in all browsers (opinionated).
 */

:where(pre) {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
  overflow: auto; /* 3 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Add the correct text decoration in Safari.
 */

:where(abbr[title]) {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

:where(b, strong) {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

:where(code, kbd, samp) {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

:where(small) {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/*
 * Change the alignment on media elements in all browsers (opinionated).
 */

:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

/**
 * Remove the border on iframes in all browsers (opinionated).
 */

:where(iframe) {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */

:where(svg:not([fill])) {
  fill: currentColor;
}

/* Tabular data
 * ========================================================================== */

/**
 * 1. Collapse border spacing in all browsers (opinionated).
 * 2. Correct table border color inheritance in all Chrome, Edge, and Safari.
 * 3. Remove text indentation from table contents in Chrome, Edge, and Safari.
 */

:where(table) {
  border-collapse: collapse; /* 1 */
  border-color: inherit; /* 2 */
  text-indent: 0; /* 3 */
}

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

:where(button, input, select) {
  margin: 0;
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */

:where(button, [type="button" i], [type="reset" i], [type="submit" i]) {
  -webkit-appearance: button;
}

/**
 * Change the inconsistent appearance in all browsers (opinionated).
 */

:where(fieldset) {
  border: 1px solid #a0a0a0;
}

/**
 * Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */

:where(progress) {
  vertical-align: baseline;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 3. Change the resize direction in all browsers (opinionated).
 */

:where(textarea) {
  margin: 0; /* 1 */
  resize: vertical; /* 3 */
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */

:where([type="search" i]) {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct styles in Safari.
 */

:where(dialog) {
  background-color: white;
  border: solid;
  color: black;
  height: -moz-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: fit-content;
}

:where(dialog:not([open])) {
  display: none;
}

/*
 * Add the correct display in Safari.
 */

:where(details > summary:first-of-type) {
  display: list-item;
}

/* Accessibility
 * ========================================================================== */

/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */

:where([aria-busy="true" i]) {
  cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */

:where([aria-controls]) {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */

:where([aria-disabled="true" i], [disabled]) {
  cursor: not-allowed;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */

:where([aria-hidden="false" i][hidden]) {
  display: initial;
}

:where([aria-hidden="false" i][hidden]:not(:focus)) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

/**
 * 1. Change the inconsistent appearance in all browsers (opinionated).
 * 2. Add typography inheritance in all browsers (opinionated).
 */

:where(button, input, select, textarea) {
  background-color: transparent; /* 1 */
  border: 1px solid WindowFrame; /* 1 */
  color: inherit; /* 1 */
  font: inherit; /* 2 */
  letter-spacing: inherit; /* 2 */
  padding: 0.25em 0.375em; /* 1 */
}

/**
 * Change the inconsistent appearance in all browsers (opinionated).
 */

:where(select) {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='4'%3E%3Cpath d='M4 0h6L7 4'/%3E%3C/svg%3E") no-repeat right center / 1em;
  border-radius: 0;
  padding-right: 1em;
}

/**
 * Don't show the arrow for multiple choice selects
 */

:where(select[multiple]) {
  background-image: none;
}

/**
 * Remove the border and padding in all browsers (opinionated).
 */

:where([type="color" i], [type="range" i]) {
  border-width: 0;
  padding: 0;
}

/**
 * Use the default user interface font in all browsers (opinionated).
 */

html {
  font-family:
    system-ui,
    /* macOS 10.11-10.12 */ -apple-system,
    /* Windows 6+ */ "Segoe UI",
    /* Android 4+ */ "Roboto",
    /* Ubuntu 10.10+ */ "Ubuntu",
    /* Gnome 3+ */ "Cantarell",
    /* KDE Plasma 5+ */ "Noto Sans",
    /* fallback */ sans-serif,
    /* macOS emoji */ "Apple Color Emoji",
    /* Windows emoji */ "Segoe UI Emoji",
    /* Windows emoji */ "Segoe UI Symbol",
    /* Linux emoji */ "Noto Color Emoji";
}

/**
 * Use the default monospace user interface font in all browsers (opinionated).
 */

code,
kbd,
samp,
pre {
  font-family:
    ui-monospace,
    /* macOS 10.10+ */ "Menlo",
    /* Windows 6+ */ "Consolas",
    /* Android 4+ */ "Roboto Mono",
    /* Ubuntu 10.10+ */ "Ubuntu Monospace",
    /* KDE Plasma 5+ */ "Noto Mono",
    /* KDE Plasma 4+ */ "Oxygen Mono",
    /* Linux/OpenOffice fallback */ "Liberation Mono",
    /* fallback */ monospace,
    /* macOS emoji */ "Apple Color Emoji",
    /* Windows emoji */ "Segoe UI Emoji",
    /* Windows emoji */ "Segoe UI Symbol",
    /* Linux emoji */ "Noto Color Emoji";
}

@font-face {
font-family: '__effra_aba036';
src: url(/_next/static/media/8f59cc28271c0cfb-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__effra_aba036';
src: url(/_next/static/media/2491db93690ed097-s.p.woff) format('woff');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__effra_aba036';
src: url(/_next/static/media/1b48386185e58475-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__effra_aba036';
src: url(/_next/static/media/02d33f0833c15d69-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__effra_Fallback_aba036';src: local("Arial");ascent-override: 94.92%;descent-override: 27.19%;line-gap-override: 0.00%;size-adjust: 96.60%
}.__className_aba036 {font-family: '__effra_aba036', '__effra_Fallback_aba036'
}.__variable_aba036 {--effra: '__effra_aba036', '__effra_Fallback_aba036'
}

@font-face {
font-family: '__hornbill_7b8499';
src: url(/_next/static/media/99bc2d91a54dbfba-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__hornbill_7b8499';
src: url(/_next/static/media/e64e4474308ced72-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__hornbill_7b8499';
src: url(/_next/static/media/de8414bc13d23cc0-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__hornbill_7b8499';
src: url(/_next/static/media/e6d8d4ae2866ae5b-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__hornbill_7b8499';
src: url(/_next/static/media/ca94e4ce0f95cfb0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__hornbill_7b8499';
src: url(/_next/static/media/a18c07f0f689bdf8-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__hornbill_7b8499';
src: url(/_next/static/media/41cb67944da5d4ef-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__hornbill_7b8499';
src: url(/_next/static/media/6e6a75c21f6697fe-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__hornbill_7b8499';
src: url(/_next/static/media/54ece181c7530eef-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__hornbill_Fallback_7b8499';src: local("Arial");ascent-override: 83.77%;descent-override: 27.92%;line-gap-override: 18.61%;size-adjust: 107.44%
}.__className_7b8499 {font-family: '__hornbill_7b8499', '__hornbill_Fallback_7b8499'
}.__variable_7b8499 {--hornbill: '__hornbill_7b8499', '__hornbill_Fallback_7b8499'
}

html,
body {
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  -webkit-margin-before: unset;
          margin-block-start: unset;
  /* margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset; */
  /* font-weight: bold;  */
}

:where(nav li)::before {
  content: none !important;
  float: none !important;
}

p:last-child {
  -webkit-margin-after: unset;
          margin-block-end: unset;
}

cite {
  font-style: normal;
}

figure,
ul {
  -webkit-margin-before: unset;
          margin-block-start: unset;
  -webkit-margin-after: unset;
          margin-block-end: unset;
  -webkit-margin-start: unset;
          margin-inline-start: unset;
  -webkit-margin-end: unset;
          margin-inline-end: unset;
  -webkit-padding-start: unset;
          padding-inline-start: unset;
}

